import React from 'react';

const ContactPage = () => {
 
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2>Contact Us</h2>
        <section className="video-section">
          <video width="640" height="360" autoPlay loop controls={false}>
            <source src="./arttrops-contactme.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </section>
        <section>
          <p>Have a project in mind? Reach out to us!</p>
          <p>Email us at:</p>
          <h2> arttropsreply@gmail.com</h2>
      </section>
      <section>
        <p>Stay connected with us on social media for updates,</p> 
          <p>behind-the-scenes glimpses, and more! </p>
          <h2>Let's collaborate and bring your creative ideas.</h2>
      </section>
    </div>
  );
};

export default ContactPage;