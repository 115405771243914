// src/components/NFTCard.js
import React from 'react';
import './NFTCard.css';

const NFTCard = ({ name, description, imageUrl, openseaUrl, backgroundColor }) => {
  const handleClick = () => {
    window.open(openseaUrl, '_blank');
  };
  const handleRightClick = (e) => {
    e.preventDefault();
    // You can add additional logic or leave it empty
  };

  return (
    <div className="nft-card" onClick={handleClick} onContextMenu={handleRightClick} style={{ backgroundColor, color: 'white' }}>
      <img src={imageUrl} alt={name} />
      <div>
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default NFTCard;
