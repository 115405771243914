import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="social-media">
          <a href="https://x.com/arttrops" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
            <img src="/twitter-squared.gif" alt="Twitter" style={{ width: '30px', height: '30px', cursor: 'pointer' }} />
          </a>
          <a href="https://www.instagram.com/arttrops/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
            <img src="/instagram.gif" alt="Instagram" style={{ width: '30px', height: '30px', cursor: 'pointer' }} />
          </a>
          <a href="https://www.youtube.com/@Arttrops?sub_confirmation=1" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
            <img src="/youtube.gif" alt="Instagram" style={{ width: '30px', height: '30px', cursor: 'pointer' }} />
          </a>
          {/* Add other social media icons */}
        </div>
      </div>
      <div className="copyright">
        <p style={{ fontWeight: 'bold', fontSize: '20px', color: 'white' }}>
          &copy; {new Date().getFullYear()} ArtTrops. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
