// src/components/AboutPage.js
import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about-us-page">
     <section className="video-section">
      <video width="640" height="360" autoPlay loop controls={false}>
        <source src="./arttrops.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </section> 
      <section className="story-section">
        <h2>Our Story</h2>
        <p className="story-text">
          Born from the fusion of art and blockchain, NFT Creators Unleashed is a collective of visionary artists and tech enthusiasts dedicated to pushing the boundaries of digital expression. Our journey began in [Year], driven by a passion for creating unique, immutable digital assets that empower artists and collectors alike.
        </p>
      </section>

      <section className="mission-section">
        <h2>Our Mission</h2>
        <p>
          Our mission is to democratize art creation and ownership in the digital realm. We believe in the power of blockchain to provide transparency, security, and authenticity to digital art, making it accessible to everyone, everywhere. Through our NFTs, we strive to create a world where artists are rightfully recognized and rewarded for their creations.
        </p>
      </section>
    </div>
  );
};

export default AboutPage;
