import React, { useState } from 'react';
import NFTCard from './NFTCard';

const NFTList = ({ nfts }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the indexes for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Extract the NFTs for the current page
  const currentNFTs = nfts.slice(startIndex, endIndex);

  const totalPages = Math.ceil(nfts.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <div className="nft-list">
        {currentNFTs.length > 0 ? (
          currentNFTs.slice().reverse().map((nft) => (
            <NFTCard
              key={nft.identifier}
              name={nft.name}
              description={nft.description}
              imageUrl={nft.image_url}
              openseaUrl={nft.opensea_url}
              backgroundColor="#000000"
            />
          ))
        ) : (
          <p>No NFTs available</p>
        )}
      </div>
      <div style={{ marginTop: '20px' }}>
        <ul className="pagination" style={{ listStyle: 'none', padding: 0, display: 'flex', justifyContent: 'center' }}>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index} style={{ display: 'inline', marginRight: '5px' }}>
              <button
  onClick={() => handlePageChange(index + 1)}
  style={{
    borderRadius: '50%',
    border: '1px solid gold', // Change border color to gold
    padding: '10px 15px',
    cursor: 'pointer',
    background: currentPage === index + 1 ? '#000' : 'transparent',
    color: currentPage === index + 1 ? '#fff' : 'gold', // Change text color to white when active
    fontWeight: 'bold',
  }}
  onMouseEnter={(e) => (e.target.style.background = '#00f')}
  onMouseLeave={(e) => (e.target.style.background = currentPage === index + 1 ? '#000' : 'transparent')}
>
  {index + 1}
</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NFTList;
