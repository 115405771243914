// src/components/NavBar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css'

const NavBar = () => {
  return (
<nav style={{ backgroundColor: 'black', color: 'white', fontWeight: 'bold', fontStyle: 'italic', padding: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <div className="brand">
    <span className="brand-text" style={{ fontSize: '20px', textShadow: '0 0 5px red', transition: 'text-shadow 0.1s' }}>ARTTROPS</span>
  </div>
  <ul style={{ listStyle: 'none', display: 'flex', margin: 0, padding: 0, justifyContent: 'center', alignItems: 'center' }}>
    <li style={{ margin: '0 10px' }}><Link to="/" style={{ color: 'red', transition: 'color 0.3s' }} onMouseEnter={(e) => e.target.style.color = 'white'} onMouseLeave={(e) => e.target.style.color = 'red'}>Home</Link></li>
    <li style={{ margin: '0 10px' }}><Link to="/random-nft" style={{ color: 'red', transition: 'color 0.3s' }} onMouseEnter={(e) => e.target.style.color = 'white'} onMouseLeave={(e) => e.target.style.color = 'red'}>Random NFT</Link></li>
    <li style={{ margin: '0 10px' }}><Link to="/about" style={{ color: 'red', transition: 'color 0.3s' }} onMouseEnter={(e) => e.target.style.color = 'white'} onMouseLeave={(e) => e.target.style.color = 'red'}>About</Link></li>
    <li style={{ margin: '0 10px' }}><Link to="/contact" style={{ color: 'red', transition: 'color 0.3s' }} onMouseEnter={(e) => e.target.style.color = 'white'} onMouseLeave={(e) => e.target.style.color = 'red'}>Contact Us</Link></li>
  </ul>
</nav>


  );
};

export default NavBar;
