import './App.css';
import React, { useEffect, useState } from 'react';

import NFTList from './Components/NFTList';

import AboutPage from './Components/AboutPage';
import ContactPage from './Components/ContactPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import NavBar from './Components/NavBar';
import RandomNFTPage from './Components/RandomNFTPage';

import Footer from './Components/Footer';

function App() {
  const [nfts, setNFTs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const apiKey = process.env.REACT_APP_API_KEY;
        const accountN = '0xb823BDCD5f4E44185167fD0F5cA14e825646D581';

        const options = {
          method: 'GET',
          headers: {accept: 'application/json', 'x-api-key': apiKey}
        };       
        //response ;
        fetch('https://api.opensea.io/api/v2/chain/ethereum/account/'+accountN+'/nfts', options)
          .then(response => response.json())
          .then(data => setNFTs(data.nfts))
          .catch(err => console.error(err));



      } catch (error) {
        console.error('Error fetching NFTs:', error);
      }
    };

    fetchData();
  }, []);

  
//#EAD6CD
  return (
    <Router>
    <div className="App" style={{ backgroundColor: 'black', color: 'white' }}>
      <NavBar />
      <div>
      <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/random-nft" element={<RandomNFTPage />} />
          <Route path="/" element={<NFTList nfts={nfts} />} />
        </Routes>
        </div>
        <Footer />
      </div>
      </Router>
  );
}

export default App;
