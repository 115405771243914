import React, { useState, useEffect } from 'react';

const RandomNFTPage = () => {
  const [randomNFT, setRandomNFT] = useState(null);
  const [nfts, setNFTs] = useState([]);

  useEffect(() => {
    // Function to fetch NFTs
    const fetchNFTs = async () => {
      try {

        const apiKey = process.env.REACT_APP_API_KEY;
        const accountN = '0xb823BDCD5f4E44185167fD0F5cA14e825646D581';
        
        const options = {
          method: 'GET',
          headers: { accept: 'application/json', 'x-api-key': apiKey },
        };
  
        // Fetch NFTs from OpenSea
        const response = await fetch('https://api.opensea.io/api/v2/chain/ethereum/account/'+accountN+'/nfts', options);
        const data = await response.json();

        // Update the NFTs state
        setNFTs(data.nfts);

        // If there are NFTs, select a random one
        if (data.nfts && data.nfts.length > 0) {
          const randomIndex = Math.floor(Math.random() * data.nfts.length);
          const randomNFT = data.nfts[randomIndex];
          setRandomNFT(randomNFT);
        } else {
          console.error('No assets found.');
        }
      } catch (error) {
        console.error('Error fetching NFTs:', error);
      }
    };

    // Call the function to fetch NFTs on page load
    fetchNFTs();
  }, []);

  // Function to handle the button click and select a new random NFT
  const handleButtonClick = () => {
    if (nfts && nfts.length > 0) {
      const randomIndex = Math.floor(Math.random() * nfts.length);
      const randomNFT = nfts[randomIndex];
      setRandomNFT(randomNFT);
    } else {
      console.error('No assets found.');
    }
  };

  // Function to handle right-click (context menu)
  const handleRightClick = (event) => {
    event.preventDefault(); // Prevent the context menu from appearing
  };

  // Function to handle redirecting to OpenSea link on image click
  const handleImageClick = () => {
    if (randomNFT && randomNFT.opensea_url) {
      window.open(randomNFT.opensea_url, '_blank'); // Open in a new tab
    }
  };

  return (
    <div>
      <h2>Random NFT Page</h2>

      {randomNFT && (
        <div>
          {/* Display the image with overlay and redirect on click */}
          <div
            className="nft-container"
            onClick={handleImageClick}
            onContextMenu={handleRightClick}
            style={{ position: 'relative', cursor: 'pointer' }}
          >
            <img
              src={randomNFT.image_url}
              alt={randomNFT.name}
              style={{ width: '50%', borderRadius: '10px' }}
            />
            <div
              className="watermark"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'rgba(255, 255, 255, 0.5)',
                fontSize: '24px',
                fontWeight: 'bold',
                pointerEvents: 'none',
              }}
            >
              ARTTROPS
            </div>
          </div>

          {/* Display the button to fetch a new random NFT */}
          <button
            onClick={handleButtonClick}
            style={{
              display: 'block',
              margin: '10px auto',
              padding: '8px 16px',
              borderRadius: '20px',
              backgroundColor: '#4A90E2',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              transition: 'background-color 0.3s, box-shadow 0.3s',
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#4285F4';
              e.target.style.boxShadow = '0 0 10px 2px #ff0000';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = '#4A90E2';
              e.target.style.boxShadow = 'none';
            }}
          >
             Random NFT
          </button>
        </div>
      )}
    </div>
  );
};

export default RandomNFTPage;
